import { useRoutes, useNavigate, useLocation } from "react-router-dom";

import NotFound from "../views/pages/sessions/NotFound";

// routes
import MainRoutes from "./MainRoutes";
import AuthenticationRoutes from "./AuthenticationRoutes";
import { useDispatch } from "react-redux";
import { loggin } from "store/login";
import { useEffect } from "react";
import APIKit from "config/APIKit";
// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
  // const logged = useSelector(state => state.logged.isLogged);
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const key = localStorage.getItem("accessToken");
  const navigate = useNavigate();
  useEffect(() => {
    const fetchData = async () => {
      try {
        if (key) {
          const res = await APIKit.get('/me');
          if (res.status) {
            dispatch(
              loggin({
                token: key,
                user: {
                  me: res.data,
                  detail:{
                    details : res.user
                  }
                },
              })
            );
            const targetPath = pathname === '/login' ? '/dashboard' : pathname;
            localStorage.setItem("storeType", res.user?.Store?.store_type);
            navigate(targetPath || '/dashboard');
          }
        } else {
          navigate("/login");
          localStorage.clear();
        }
      } catch (err) {
        console.error(err);
        navigate("/login");
        localStorage.clear();
      }
    };

    fetchData(); // Call the async function
  }, [key]); // Add dependencies if needed
  return useRoutes([
    MainRoutes,
    AuthenticationRoutes,
    // { path: "/", element: <Navigate to={logged ? "dashboard" : "login"} /> },
    { path: "*", element: <NotFound /> },
  ]);
}
